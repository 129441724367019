<script setup>
  import CoffeeNeon from '@/components/CoffeeNeon'
  import XmaxDecoration from '@/components/XmasDecoration'
</script>

<template>
  <div id="container">
    <div id="pattern-bg"></div>
    <section id="content">
      <main>
        <div class="hk-visuals-container">
          <div class="hk-visual-img-holder">
            <CoffeeNeon/>
          </div>

          <h1>We're brewing something...</h1>
          <p>But we're not there yet. Come back later.</p>
          
          <XmaxDecoration/>
        </div>
      </main>

      <footer>
          <p>Za päť eur včera.</p>
          <div>|</div>
          <a href="./">Khavila</a>
          <p style="margin-left: 0.7em;">{{ date }}</p>
      </footer>
    </section>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      date: null,
    }
  },
  mounted() {
    const date = new Date()
    this.date = date.getFullYear()
  },
}
</script>

<style lang="scss">
@import '@/styles/variables';
  html {
    background-color: $background_main;
  }
  body {
    background-color: $background_main;
    margin: 0;
  }
  #container {
    position: relative;
  }
  #content {
    position: relative;
    z-index: 10;
    background: radial-gradient(circle, transparent 0%, $background_secondary 80%);
  }
  #pattern-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background-image: url('@/assets/xmas_background.svg');
    opacity: 0.2;
  }
  main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3em;
    min-height: calc(100vh - 3em - 3em);
  }
  footer {
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background_footer;
    height: 3em;
    div {
      margin: 0 1.5em;
      color: gray;
    }
    p,a {
      font-family: Manrope;
      color: #aaa;
      font-size: 0.9rem;
    }
    a {
      text-decoration: none;
      transition: all 0.1s;
      &:hover {
        color: $accent_main;
        transition: all 0.2s;
      }
    }
  }
  .hk-visual-img-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.55em;
  }
  .hk-visuals-container {
    p {
      text-align: center;
      color: #888;
      font-family: Manrope;
      font-size: 0.9rem;
      margin-top: 0.3rem;
    }
    h1 {
      font-family: Lobster;
      color: $accent_main;
      text-align: center;
      margin-bottom: 0;
    }
    @media only screen and (max-width: 599.99px) {
      h1 {
        line-height: 2rem !important;
        font-size: 1.7rem !important;
        margin-top: 3rem;
      }
    }
    @media only screen and (min-width: 600px) {
      font-size: 2.2rem !important;
    }
  }

</style>
